<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-btn icon @click="pesquisar">
            <v-icon>search</v-icon>
          </v-btn>
          <v-text-field
            flat
            solo
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="cadastro">
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="pedidos"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.nomeFornecedor }}</td>
            <td class="text-xs-left">{{ props.item.dataPedidoString }}</td>
            <td class="text-xs-left">{{ props.item.dataPrevistaEntregaString }}</td>
            <td>{{ props.item.quantidade }}</td>
            <td class="text-xs-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" depressed icon dark v-bind="attrs" v-on="on">
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-btn depressed icon dark color="primary" title="Visualizar" small @click="view(props.item.id)">
                    <v-icon small>remove_red_eye</v-icon>
                  </v-btn>
                  <v-btn depressed icon dark color="primary" title="Editar" small @click="editar(props.item.id)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { ServicoPedido } from "../../servicos/servicoPedido"
const servicoPedido = new ServicoPedido()
export default {
  data() {
    return {
      search: "",
      pedidos: [],
      pedidoSemfiltro: [],
      headers: [
        { text: "Id", align: "right", sortable: false, value: "id" },
        { text: "Fornecedor", align: "left", sortable: false, value: "nomeFornecedor" },
        { text: "Data do pedido", align: "left", sortable: false, value: "dataPedidoString" },
        { text: "Data prevista entrega", align: "left", sortable: false, value: "dataPrevistaEntregaString" },
        { text: "Qtd", align: "right", sortable: false, value: "quantidade" },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    servicoPedido.getAllTable().then(
      res => {
        if (res.status === 200) {
          this.pedidos = res.data
          this.pedidoSemfiltro = this.pedidos
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },
  methods: {
    view(id) {
      this.$router.push({ name: "view-pedido", params: { id: id } })
    },
    cadastro() {
      this.$router.push({ name: "cadastro-pedido" })
    },
    pesquisar() {
      if (this.search === "") {
        this.pedidos = this.pedidoSemfiltro
      } else {
        this.pedidos = this.pedidoSemfiltro
        var result = this.pedidos.filter(obj => {
          return (
            obj.nomeFornecedor
              .toString()
              .toUpperCase()
              .includes(this.search.toUpperCase(), 0) ||
            obj.id.toString().includes(this.search, 0) ||
            obj.dataPedidoString.toString().includes(this.search, 0) ||
            obj.dataPrevistaEntregaString.toString().includes(this.search, 0) ||
            obj.quantidade.toString().includes(this.search, 0)
          )
        })
        this.pedidos = result
      }
    },
    editar(id) {
      this.$router.push({ name: "alterar-pedido", params: { id: id } })
    }
  }
}
</script>
