<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <pedido-table></pedido-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import PedidoTable from "@/components/pedido/tablePedido"
export default {
  components: {
    PedidoTable
  }
}
</script>
