import axios from "axios"
export class ServicoPedido {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Pedido`
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
  getAllTable() {
    return axios({
      url: this.urlServico + `/GetAllTable`,
      method: "GET"
    })
  }
  salvar(pedido, produtos, pagamentos) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", { pedido, produtos, pagamentos }, config).catch(error => {
      alert("Erro ao salvar um novo pedido " + error)
      return Promise.reject(error)
    })
  }
}
